<template>
  <div class="home">
    <secPopup v-if="openPopup" @close="openPopup = false" />
    <div class="home-header">
      <div class="home-header-inner">
        Помощник расчета стоимости оборудования охранной системы для бизнеса
      </div>
    </div>
    <div class="home-grid">
      <div class="home-grid-left">
        <div class="home-text-1">
          Расчет стоимости комплекта охранной системы для бизнеса онлайн за 2
          минуты
        </div>
        <div class="home-text-2">
          Пройдите короткий тест и узнайте стоимость оптимального комплекта для
          Вас
        </div>
        <div class="home-button" @click="openPopup = true">РАССЧИТАТЬ</div>
        <div class="home-goods-grid">
          <div class="good">
            <div class="good-text">
              Подробное <br />
              описание
            </div>
            <div class="good-image good-image-1"></div>
          </div>
          <div class="good">
            <div class="good-text">точный <br />расчет</div>
            <div class="good-image good-image-2"></div>
          </div>
          <div class="good">
            <div class="good-text">
              2 минуты на <br />
              тест
            </div>
            <div class="good-image good-image-3"></div>
          </div>
        </div>
      </div>
      <div class="home-grid-right"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import secPopup from "@/components/secPopup.vue";

export default {
  name: "Home",
  components: {
    secPopup
  },
  data() {
    return {
      openPopup: false
    };
  }
};
</script>

<style lang="scss">
.home {
  .home-header {
    width: 100%;

    .home-header-inner {
      width: 70%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      font-size: 24px;
      padding-top: 13px;
      padding-bottom: 13px;
      padding-left: 5px;
      padding-right: 5px;
      background: linear-gradient(90deg, #2147d3 0%, #5bb395 100%);
      color: #fff;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
    }
  }
  .home-grid {
    width: 70%;
    margin: 80px auto 0 auto;
    min-height: 70vh;
    display: grid;
    grid-template-columns: 10fr 9fr;

    .home-grid-left {
      .home-text-1 {
        font-size: 30px;
        line-height: 37px;
        margin-bottom: 30px;
        width: 90%;
      }

      .home-text-2 {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 30px;
        width: 90%;
      }

      .home-button {
        margin: 50px auto 0 auto;
        width: 300px;
        font-size: 24px;
        text-align: center;
        color: #fff;
        padding-top: 13px;
        padding-bottom: 13px;
        background: linear-gradient(
          90deg,
          rgba(91, 179, 149, 0.75) 0%,
          rgba(33, 71, 211, 0.75) 50.52%,
          rgba(199, 92, 92, 0.75) 100%
        );
        border-radius: 25px;
        cursor: pointer;
      }

      .home-goods-grid {
        margin-top: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
        align-items: center;

        .good {
          width: 140px;

          .good-text {
            text-transform: uppercase;
            text-align: center;
          }

          .good-image {
            height: 100px;
            width: 100%;
            background-size: auto 100%;
            background-position: center;
            background-repeat: no-repeat;
          }

          .good-image-1 {
            background-image: url("~@/assets/img/add-1.svg");
          }
          .good-image-2 {
            background-image: url("~@/assets/img/add-2.svg");
          }
          .good-image-3 {
            background-image: url("~@/assets/img/add-3.svg");
          }
        }
      }
    }

    .home-grid-right {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-image: url("~@/assets/img/home-right.svg");
    }
  }
}

@media (max-width: 1200px) {
  .home {
    .home-header {
      .home-header-inner {
        width: 95%;
      }
    }
    .home-grid {
      width: 95%;
      display: block;

      .home-grid-right {
        display: none;
      }
    }
  }
}

@media (max-width: 500px) {
  .home-goods-grid {
    grid-template-columns: 1fr !important;
    grid-gap: 50px !important;
    margin-bottom: 50px !important;
  }
}
</style>

export function GETONELEFTPERCENT(state) {
  return state.oneLeftReadyPercent;
}
export function GETONERIGHTPERCENT(state) {
  return state.oneRightReadyPercent;
}

export function GETFILLPERCENT(state) {
  return Math.floor(
    state.oneLeftReadyPercent / 2 + state.oneRightReadyPercent / 2
  );
}

export function GETISFIRSTPAGEREADY(state) {
  if (state.oneLeftReadyPercent == 100 && state.oneRightReadyPercent == 100) {
    return true;
  }
  return false;
}

export function GETDATA(state) {
  return state.popupsData;
}
export function GETPRICIES(state) {
  return state.pricies;
}

export function GETTWORIGHTDATA(state) {
  return state.popupsData.twoRight;
}
export function GETTEXT(state) {
  return state.texts;
}

export function GETSECTYPE(state) {
  return state.popupsData.securityType;
}

export function GETBUTTONTYPE(state) {
  return state.popupsData.securityButtonType;
}

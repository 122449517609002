import Vue from "vue";
import Vuex from "vuex";
import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";

Vue.use(Vuex);

export default new Vuex.Store({
  state: state,

  mutations: mutations,

  getters: getters,
  actions: {},
  modules: {}
});

export default {
  oneLeftReadyPercent: 0,
  oneRightReadyPercent: 0,

  popupsData: {
    securityReaction: null,
    entriesQuantity: null,
    windowsQuantity: null,
    securityType: null,
    securityButtonType: null,
    stationarPanelsQuantity: 0,

    moveDetectorsQuantity: 0,
    moveDetectorSecuresQuantity: 0,
    doreOpeningDetectorsQuantity: 0,
    controlPanelsQuantity: 0,

    termometersQuantity: 0,
    smokeDetectorsQuantity: null,
    waterDetectorsQuantity: null,
    gasDetectorsQuantity: null,
    videoMonitoring: false
  },

  pricies: {
    moveDetector: 1700,
    moveDetectorSecure: 2300,
    doreOpeningDetector: 1500,
    controlPanel: 1000,
    smokeDetector: 2000,
    waterDetector: 2000,
    gasDetector: 2000,
    termometer: 750,
    stationarPanel: 1500
  },

  texts: {
    1: "Нужно ли Вам, чтобы при сработке датчиков (потенциальном проникновении неустановленных лиц на объект), на объект выезжала группа с 2мя вооруженными бойцами и водителем?",
    2: "Охранная сигнализация - от проникновений в ночное время. Тревожная кнопка - от проникновений и беспорядков в дневное время. Комплексная охрана - и то, и другое.",
    3: "От количества входов зависим количество дверных датчиков, требующихся для надежной охраны объекта. Укажите количество дверей, с помощью которых можно войти на объект.",
    4: "Укажите точное количество ПОМЕЩЕНИЙ С ОКНАМИ (НЕ КОЛИЧЕСТВО ОКОН). В каждое помещение с окном, в которое сможет пролезть человек, необходимо установить датчик движения.",
    5: "Физическое оборудование - брелок или стационарная тревожная кнопка. Мобильное приложение - тревожная кнопка, которая активируется в приложении (оборудование не требуется, то есть платите только абонентскую плату)",
    6: "Укажите удобное для Вас количество брелков, с помощью которых вы сможете активировать тревожную кнопку и снимать объект с охраны.",
    7: "Укажите количество стационарных тревожных кнопок, которое Вам необходимо."
  }
};

<template>
  <div id="sec-popup">
    <div class="popup-holder">
      <div class="popup-close" @click="close()"></div>
      <div class="popup-progress-holder">
        <div class="popup-progress-percent">{{ GETFILLPERCENT }}%</div>
        <div class="popup-progress">
          <div
            class="popup-progress-inner"
            :style="'width:' + GETFILLPERCENT + '%'"
          ></div>
        </div>
      </div>
      <div class="popup-grid">
        <div class="popup-grid-left">
          <secOneLeft v-show="step == 1" />
          <secThreeLeft v-show="step == 2 || step == 3" />
        </div>
        <div class="popup-grid-right">
          <secOneRight @next="step = 2" v-show="step == 1" />
          <secThreeRight
            @openSubmitPopup="step = 3"
            v-show="step == 2 || step == 3"
          />
        </div>
      </div>
    </div>
    <secSubmitPopup @closeSubmitPopup="step = 3" v-show="step == 4" />
  </div>
</template>

<script>
const secOneLeft = () => import("@/components/screenOne/secOneLeft");
const secOneRight = () => import("@/components/screenOne/secOneRight");

const secThreeLeft = () => import("@/components/screenThree/secThreeLeft");
const secThreeRight = () => import("@/components/screenThree/secThreeRight");

const secSubmitPopup = () => import("@/components/secSubmitPopup");

import { mapGetters } from "vuex";
export default {
  name: "sec-popup",
  components: {
    secOneLeft,
    secOneRight,
    secThreeLeft,
    secThreeRight,
    secSubmitPopup
  },
  data() {
    return {
      step: 1
    };
  },
  computed: {
    ...mapGetters(["GETFILLPERCENT"])
  },
  methods: {
    close() {
      this.$emit("close");
    }
  },
  mounted() {
    document.body.style.overflowY = "hidden";
  },
  destroyed() {
    document.body.style.overflowY = "auto";
  }
};
</script>

<style lang="scss">
#sec-popup {
  height: 100vh;
  width: 100vw;
  position: fixed;
  overflow: scroll;
  left: 0;
  top: 0;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  background-color: rgba($color: #000000, $alpha: 0.5);

  .popup-holder {
    width: 72%;
    min-width: 800px;
    background-color: white;
    border-radius: 30px;
    padding-bottom: 13px;
    min-height: 85vh;

    .popup-close {
      height: 35px;
      width: 35px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-image: url(./../assets/img/close.svg);
      margin: 13px;
      margin-left: auto;
      cursor: pointer;
      position: relative;
      z-index: 1;
    }

    .popup-progress-holder {
      margin-top: -50px;

      .popup-progress-percent {
        margin: 0 auto 0 auto;
        width: 90%;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 15px;
      }

      .popup-progress {
        margin: 0 auto 0 auto;
        height: 20px;
        width: 90%;
        border: 2px solid #000;
        border-radius: 30px;
      }

      .popup-progress-inner {
        height: 100%;
        border-radius: 30px;
        background-color: #5bb395;
      }
    }

    .popup-grid {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 5%;
      min-height: 100px;
    }
  }
}

@media (max-width: 848px) {
  #sec-popup {
    align-items: flex-start;
    overflow-y: scroll;
    display: block;

    .popup-holder {
      height: auto;
      width: 95%;
      min-width: 0;
      background-color: white;
      border-radius: 30px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 5px;

      .popup-grid {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        grid-template-columns: 1fr;
        grid-gap: 5%;
        min-height: 100px;
        display: block;
      }
    }
  }
}
</style>

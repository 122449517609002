export function SETONELEFTPERCENT(state, inner) {
  state.oneLeftReadyPercent = inner;
}
export function SETONERIGHTPERCENT(state, inner) {
  state.oneRightReadyPercent = inner;
}

export function SETDATA(state, data) {
  for (let i in data.data) {
    if (data.data[i] !== null) {
      state.popupsData[i] = data.data[i];
    }
  }
  if (state.popupsData.securityButtonType == "app") {
    state.popupsData.stationarPanelsQuantity = 0;
    state.popupsData.controlPanelsQuantity = 0;
  }
  //количество датчиков дверей = кол. входов
  state.popupsData.doreOpeningDetectorsQuantity =
    state.popupsData.entriesQuantity;
  //количество датчиков движения = кол. окон
  state.popupsData.moveDetectorSecuresQuantity = 0;
  state.popupsData.moveDetectorsQuantity = state.popupsData.windowsQuantity;

  if (state.popupsData.moveDetectorsQuantity == null) {
    state.popupsData.moveDetectorsQuantity = 0;
  }
  if (state.popupsData.doreOpeningDetectorsQuantity == null) {
    state.popupsData.doreOpeningDetectorsQuantity = 0;
  }

  if (state.popupsData.securityType == "secureComplex") {
    if (state.popupsData.moveDetectorsQuantity > 0) {
      state.popupsData.moveDetectorsQuantity--;
    }

    if (state.popupsData.doreOpeningDetectorsQuantity > 0) {
      state.popupsData.doreOpeningDetectorsQuantity--;
    }
  }

  if(state.popupsData.controlPanelsQuantity<=2){
    state.popupsData.controlPanelsQuantity=0;
  }
}

export function ADD(state, inner) {
  console.log(state.popupsData[inner]);
  if (inner == "videoMonitoring") {
    state.popupsData[inner] = !state.popupsData[inner];
    console.log(state.popupsData[inner]);
    return;
  }

  if (state.popupsData[inner] === null) {
    state.popupsData[inner] = 1;
    return;
  }
  state.popupsData[inner]++;
}

export function SUB(state, inner) {
  if (state.popupsData[inner] === null) {
    state.popupsData[inner] = 0;
    return;
  }
  if (state.popupsData[inner] === 0) {
    return;
  }
  state.popupsData[inner]--;
}
